import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {
    Button,
    Modal,
    Table,
    Nav,
    Navbar,
    Container,
    Alert,
    FloatingLabel,
    Form,
    Spinner,
    ListGroup
} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import Login from "./login";
import { FaAngleLeft } from "react-icons/fa6";
import {API_SERVER} from "../devConfig";
import {loginUser} from "../actions/userActions";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

export default function Service () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    return (
        <div>
            <div style={{
                textAlign:'center',
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
            }}>
                <div style={{
                    fontSize:20,
                    width:'100%', height:60, display:'flex', flexDirection:'row',
                    justifyContent:'space-between', alignItems:'center'
                }}>
                    <div
                        className={'_click'}
                        onClick={()=>{
                            navigator('/')
                        }}
                        style={{width:80,
                            textAlign:'left',
                            paddingLeft:15,
                        }}>
                        <FaAngleLeft/>
                    </div>
                    <span>고객센터</span>
                    <div style={{width:80}}/>
                </div>

                <div style={{width:'100%', display:'flex', flexDirection:"column", justifyContent:'center', alignItems:'center'}}>
                    <div
                        className={'_click'}
                        onClick={()=> document.location.href='http://pf.kakao.com/_fVHrG/chat'}
                        style={{
                            fontSize:16,
                            color :'black', border:'3px solid black', backgroundColor:'#f7e111',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:50, marginTop:40, width:'85%'
                        }}>
                        <span>카카오톡 문의</span>
                    </div>
                    <div
                        className={'_click'}
                        onClick={()=>window.location.href='tel:070-7720-1551'}
                        style={{
                            fontSize:16,
                            color :'white', border:'3px solid #2e58ec', backgroundColor:'#2e58ec',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:50, marginTop:40, width:'85%'
                        }}>
                        <span>070-7720-1551 전화문의</span>
                    </div>
                    <div
                        className={'_click'}
                        onClick={()=>{
                        }}
                        style={{
                            fontSize:16,
                            color :'#2e58ec', border:'3px solid #2e58ec', backgroundColor:'white',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:50, marginTop:40, width:'85%'
                        }}>
                        <CopyToClipboard text="truck119kr@gmail.com" onCopy={() => alert("클립보드에 복사되었습니다.")}>
                            <span>truck119kr@gmail.com 메일 복사</span>
                        </CopyToClipboard>

                    </div>


                    <div style={{
                        borderRadius:20,
                        justifyContent:'center',
                        display:'flex', flexDirection:'row', flexWrap:"wrap",
                        marginBottom:50,
                        marginTop:50, padding:'20px 15px 20px 15px', width:'85%', border:'3px solid lightgray'}}>

                        <div style={{                            width:'100%', justifyContent:'center', alignItems:'center', textAlign:'center',
                            marginBottom:15, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:20}}>서류 제출처</span>
                        </div>

                        <div style={{textAlign:'left', fontSize:16}}>
                            <table className={'_docu'}>
                                <tr>
                                    <td valign={'top'} style={{width:100}}>
                                        문자 제출:
                                    </td>
                                    <td>
                                        010-5836-1550<br/>
                                        <CopyToClipboard text="010-5836-1550" onCopy={() => alert("클립보드에 복사되었습니다.")}>
                                            <span style={{fontSize:16, textDecorationLine:'underline'}}>복사하기></span>
                                        </CopyToClipboard>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign={'top'}>
                                        이메일:
                                    </td>
                                    <td>
                                        truck119kr@gmail.com<br/>
                                        <CopyToClipboard text="truck119kr@gmail.com" onCopy={() => alert("클립보드에 복사되었습니다.")}>
                                            <span style={{fontSize:16, textDecorationLine:'underline'}}>복사하기></span>
                                        </CopyToClipboard>
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td valign={'top'}>*/}
                                {/*        우편 제출:*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        서울 서대문구 성산로 512-42*/}
                                {/*        이화여대 이룸센터 306호 싸인트럭 주식회사 앞<br/>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                            </table>
                        </div>

                    </div>


                </div>
            </div>

        </div>
    )
}