import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {
    Button,
    Modal,
    Table,
    Nav,
    Navbar,
    Container,
    Alert,
    FloatingLabel,
    Form,
    Spinner,
    ListGroup
} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import Login from "component/login";
import {API_SERVER} from "../devConfig";
import {loginUser} from "../actions/userActions";
import {useSelector} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

export default function AlertWindow (props) {
    console.log(props)
    const {width, height} =useWindowDimensions();

    const [step, setStep] = useState(1);
    const navigator = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalJoin, setModalJoin] = useState(false);

    const userInfo = useSelector((state) => state.userReducer.userInfo);

    useEffect(()=>{
        if(!modalOpen && props.setModalApprove){
                props.setModalApprove(false)
        }
        // else if(!modalOpen && props.setModalApprove){
        //     props.setModalApprove(false)
        // }
    },[modalJoin, modalOpen])

    useEffect(()=>{
        // setModalJoin(props['alertApprove'])
        setModalOpen(props['alertApprove'])
    }, [props])
    const renderApprove=(_info)=>{
        var result;
        switch (_info['user_type']){
            case 1:
                result =
                    <div style={{
                        borderRadius:20,
                        justifyContent:'center',
                        display:'flex', flexDirection:'row', flexWrap:"wrap",
                        marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                        <div style={{marginBottom:15, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:20}}>화물사업자 제출서류</span>
                        </div>

                        <div style={{textAlign:'left', width:'80%'}}>
                            <ListGroup as="ol" numbered className={'largee'}>
                                {
                                    [
                                        '사업자등록증 사본',
                                        // '차량등록증 사본',
                                        // '화물운송자격증 사본',
                                        // '협회 회원증 사본 (해당시)',
                                    ].map((item)=>{
                                        return(
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <span style={{fontSize:22}}>{item}</span>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </div>
                        <div style={{textAlign:'center', marginTop:10, marginBottom:10, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:16, fontWeight:'bold'}}>서류제출시 이름/전화번호 알려주세요</span>
                        </div>
                    </div>
                break;
            case 2:
                result =
                    <div style={{
                        borderRadius:20,
                        justifyContent:'center',
                        display:'flex', flexDirection:'row', flexWrap:"wrap",
                        marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                        <div style={{width:'100%', marginBottom:15, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:20, textDecoration:'underline'}}>변호사/로펌 회원 제출서류</span>
                        </div>

                        <div style={{textAlign:'left', width:'80%'}}>
                            <ListGroup as="ol" numbered className={'largee'}>
                                {
                                    [
                                        '사업자등록증 사본',
                                    ].map((item)=>{
                                        return(
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{item}</div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </div>
                        <div style={{marginTop:10, marginBottom:10, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:16, fontWeight:'bold', color:'#2e58ec'}}>*서류제출시 휴대폰 번호 알려주세요</span>
                        </div>
                    </div>
                break;
        }
        return(
            <div style={{width:'100%', textAlign:'center', fontWeight:'bold'}}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    {
                        ['서류제출','검토','승인'].map((s,i)=>{
                            return(
                                <div style={{display:'flex', flexDirection:'row',
                                    alignItems:'center',
                                    justifyContent:'center',
                                }}>
                                    {i > 0 && <div style={{marginLeft:2, marginRight:2}}><span>→</span></div>}
                                    <div style={{
                                        display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
                                        height:40,
                                        width:width*0.23,
                                        border:'3px solid black', borderRadius:15}}>
                                        <span>{s}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {result}
                </div>

                <div style={{
                    borderRadius:20,
                    justifyContent:'center',
                    display:'flex', flexDirection:'row', flexWrap:"wrap",
                    marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                    <div style={{width:'100%', marginBottom:15, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'
                    ,textAlign:'center'}}>
                        <span style={{fontSize:20}}>서류 제출처</span>
                    </div>

                    <div style={{textAlign:'left', fontSize:16}}>
                        <table className={'_docu'}>
                            <tr>
                                <td valign={'top'} style={{width:100}}>
                                    문자 제출:
                                </td>
                                <td>
                                    010-5836-1550<br/>
                                    <CopyToClipboard text="010-5836-1550" onCopy={() => alert("클립보드에 복사되었습니다.")}>
                                        <span style={{fontSize:16, textDecorationLine:'underline'}}>복사하기></span>
                                    </CopyToClipboard>
                                </td>
                            </tr>
                            <tr>
                                <td valign={'top'}>
                                    이메일:
                                </td>
                                <td>
                                    truck119kr@gmail.com<br/>
                                    <CopyToClipboard text="truck119kr@gmail.com" onCopy={() => alert("클립보드에 복사되었습니다.")}>
                                        <span style={{fontSize:16, textDecorationLine:'underline'}}>복사하기></span>
                                    </CopyToClipboard>
                                </td>
                            </tr>
                        </table>
                    </div>


                    <div
                        className={'_click'}
                        onClick={()=> document.location.href='http://pf.kakao.com/_fVHrG/chat'}
                        style={{
                            color :'black', backgroundColor:'#f7e111',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:30, width:'80%'
                        }}>
                        <span>카카오톡 문의</span>
                    </div>
                    <div
                        className={'_click'}
                        onClick={()=>window.location.href='tel:070-7720-1551'}
                        style={{
                            color :'white', backgroundColor:'#2e58ec',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:10, width:'80%'
                        }}>
                        <span>전화 문의</span>
                    </div>

                </div>
            </div>
        )
    }
    if(userInfo)
    return (
        <div style={{}}>
            <Modal
                // style={{width:'80%'}}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalOpen} onHide={()=> {
                setModalOpen(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width:'100%', border:0}}>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        fontWeight:'bold',
                        display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                        paddingTop:10, paddingBottom:10,
                        fontSize:22
                    }}>
                        <div>
                            <span style={{}}>
                                사업자 인증이 필요합니다
                            </span>
                        </div>
                        <div style={{marginTop:20, marginBottom:15, textAlign:'center', color:'gray'}}>
                            <span>인증 완료 후<br/>서비스 이용이 가능합니다</span>
                        </div>

                        <div
                            className={'_click'}
                            onClick={()=>{
                                setModalOpen(false)
                                setModalJoin(true)
                            }}
                            style={{
                                fontSize:22,
                                color :'white', border:'3px solid #2e58ec', backgroundColor:'#2e58ec',
                                borderRadius:25, display:'flex',flexDirection:'row',
                                alignItems:'center', justifyContent:'center', height:50, marginTop:30, width:'80%'
                            }}>
                            <span>인증방법 안내 ></span>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

                <Modal show={modalJoin} onHide={()=> {
                    setModalJoin(false);
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{width:'100%', border:0}}>
                            <div style={{textAlign:'center', width:'100%'}}>
                                <span>회원가입 인증 절차</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{
                        padding:0,
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center'
                    }}>
                        <div style={{width:'100%', padding:20}}>
                            {
                                renderApprove(userInfo)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <Button
                                style={{width:width*0.4}}
                                variant="primary" onClick={()=>{
                                setModalJoin(false);
                                // navigator('../')
                            }}>
                                확인
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

        </div>
    )
}