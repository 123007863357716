import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import Login from "./login";
import { FaAngleLeft } from "react-icons/fa6";

export default function Service () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    return (
        <div>
            <div style={{
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
            }}>
                <div style={{
                    fontSize:20,
                    width:'100%', height:60, display:'flex', flexDirection:'row',
                    justifyContent:'space-between', alignItems:'center'
                }}>
                    <div
                    className={'_click'}
                    onClick={()=>{
                        navigator('/')
                    }}
                        style={{width:80,
                            textAlign:'left',
                            paddingLeft:15,
                    }}>
                        <FaAngleLeft/>
                    </div>
                    <span>서비스 소개</span>
                    <div style={{width:80}}/>
                </div>

                <div style={{width:'90%', paddingTop:50}}>
                <div style={{height:10, width:20, borderTop:'3px solid black'}}/>
                <span style={{fontSize:25, fontWeight:900}}>
                    트럭119 소개
                </span>
                    <div style={{paddingTop:15}}>
                        <span style={{lineHeight:2, whiteSpace:'pre-wrap', fontWeight:'bold', fontSize:16}}>
                           변호사가 직접 설립한 회사로서{'\n'}
                            화물사업자분들께 법률복지를 제공합니다.
                        </span>
                    </div>
                </div>

                <div style={{width:'90%', paddingTop:50, paddingBottom:50}}>
                    <div style={{height:10, width:20, borderTop:'3px solid black'}}/>
                    <span style={{fontSize:25, fontWeight:900}}>
                    이용절차
                </span>
                    <div style={{marginTop:20, padding:10, backgroundColor:'#f5f5f5'}}>
                        <span style={{lineHeight:2.2, whiteSpace:'pre-wrap', fontWeight:'bold', fontSize:16}}>
                            1) 회원가입{'\n'}
                            2) 법률문제 발생시 서비스 신청{'\n'}
                            3) 서류제출{'\n'}
                            4) 완료{'\n'}
                        </span>
                    </div>
                </div>

            </div>

        </div>
    )
}