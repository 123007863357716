import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {
    Button,
    Modal,
    Table,
    Nav,
    Navbar,
    Container,
    Alert,
    FloatingLabel,
    Form,
    Spinner,
    ListGroup
} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import Notice from "component/notice";
import {useSelector} from "react-redux";
import AlertWindow from "../../lib/alertWindow";

export default function Main () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const [modalJoin, setModalJoin] = useState(false);
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const [modalApprove, setModalApprove] = useState(false)

    // useEffect(()=>{
    //     if(userInfo && !userInfo['is_approve']){
    //         window.alert('asdf')
    //     }
    // },[userInfo])

    const renderApprove=(_info)=>{
        var result;
        switch (_info['user_type']){
            case 1:
                result =
                    <div style={{
                        borderRadius:20,
                        justifyContent:'center',
                        display:'flex', flexDirection:'row', flexWrap:"wrap",
                        marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>
3
                        <div style={{
                            width:'100%', justifyContent:'center',
                            marginBottom:15, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:20, textDecoration:'underline'}}>차주회원 제출서류</span>
                        </div>

                        <div style={{textAlign:'left'}}>
                            <ListGroup as="ol" numbered>
                                {
                                    [
                                        '사업자등록증 사본',
                                        '차량등록증 사본',
                                        '화물운송자격증 사본',
                                        '협회 회원증 사본 (해당시)',
                                    ].map((item)=>{
                                        return(
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{item}</div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </div>
                        <div style={{marginTop:10, marginBottom:10, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:16, fontWeight:'bold', color:'#2e58ec'}}>*서류제출시 휴대폰 번호 알려주세요</span>
                        </div>
                    </div>
                break;
            default:
                result =
                    <div style={{
                        borderRadius:20,
                        justifyContent:'center',
                        display:'flex', flexDirection:'row', flexWrap:"wrap",
                        marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                        <div style={{marginBottom:15, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:20, textDecoration:'underline'}}>화주(주선사)회원 제출서류</span>
                        </div>

                        <div style={{textAlign:'left'}}>
                            <ListGroup as="ol" numbered>
                                {
                                    [
                                        '사업자등록증 사본',
                                        '주선면허증 사본',
                                        '협회 회원증 사본 (해당시)',
                                    ].map((item)=>{
                                        return(
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{item}</div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </div>
                        <div style={{marginTop:10, marginBottom:10, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:16, fontWeight:'bold', color:'#2e58ec'}}>*서류제출시 휴대폰 번호 알려주세요</span>
                        </div>
                    </div>
                break;
        }
        return(
            <div style={{width:'100%', textAlign:'center'}}>
                <div style={{paddingBottom:15}}>
                    <span style={{fontSize:20, fontWeight:'bold'}}>인증절차</span>
                </div>증
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    {
                        ['서류제출','검토','승인'].map((s,i)=>{
                            return(
                                <div style={{display:'flex', flexDirection:'row',
                                    alignItems:'center',
                                    justifyContent:'center',
                                }}>
                                    {i > 0 && <div style={{marginLeft:2, marginRight:2}}><span>→</span></div>}
                                    <div style={{
                                        display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
                                        height:40,
                                        width:width*0.23,
                                        border:'3px solid black', borderRadius:15}}>
                                        <span>{s}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {result}
                </div>

                <div style={{
                    borderRadius:20,
                    justifyContent:'center',
                    display:'flex', flexDirection:'row', flexWrap:"wrap",
                    marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                    <div style={{                            width:'100%', justifyContent:'center',
                        marginBottom:15, display:'flex', flexDirection:'row'}}>
                        <span style={{fontSize:20, textDecoration:'underline'}}>서류 제출처</span>
                    </div>

                    <div style={{textAlign:'left'}}>
                        <ListGroup as="ol" numbered>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">이메일제출</div>
                                    call365call@gmail.com
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">문자 제출</div>
                                    010-5836-1550
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">우편 제출</div>
                                    서울시 서대문구 대신동 34-16 싸인트럭 주식회사
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>


                    <div
                        className={'_click'}
                        onClick={()=>{

                        }}
                        style={{
                            color :'black', backgroundColor:'#f7e111',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:10, width:'60%'
                        }}>
                        <span>카카오톡 문의</span>
                    </div>
                    <div
                        className={'_click'}
                        onClick={()=>{
                        }}
                        style={{
                            color :'white', backgroundColor:'#2e58ec',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:10, width:'60%'
                        }}>
                        <span>전화 문의</span>
                    </div>

                </div>
            </div>
        )
    }
    return (
        <div style={{
            marginTop:30,
            textAlign:'center',
            justifyContent:'center', alignItems:'center', flexWrap:'wrap',
            width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
            // padding:height*0.05
        }}>
            <div style={{
                textAlign:'center',
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
                padding:height*0.02
            }}>
                <div style={{width:'100%'}}>
                    <span style={{fontSize:30}}>무엇을 도와드릴까요?</span>
                </div>
            </div>

            <div style={{
                marginTop:30, display:'flex', flexDirection:"column", alignItems:'center',
                width:'100%', textAlign:'center', fontSize:22}}>
                <span>못받은 돈이 있나요?</span>
                <div
                    className={'_click'}
                    onClick={()=>{
                        if(userInfo['is_complete'] != 'Y'){
                            setModalApprove(true)
                        }else{
                            navigator('../lawReg/1')
                        }
                    }}
                    style={{
                        border:'3px solid #2e58ec', backgroundColor:'#2e58ec', color:'white',
                        borderRadius:25, display:'flex',flexDirection:'row',
                        alignItems:'center', justifyContent:'center', height:50, marginTop:10, width:'60%'
                    }}>
                    <span>내용증명 신청 ></span>
                </div>
            </div>

            <div style={{
                marginTop:30, display:'flex', flexDirection:"column", alignItems:'center',
                width:'100%', textAlign:'center', fontSize:22}}>
                <span>사고/질병이 발생했나요?</span>
                <div
                    className={'_click'}
                    onClick={()=>{
                        if(userInfo['is_complete'] != 'Y'){
                            setModalApprove(true)
                        }else{
                            navigator('../lawReg/2')
                        }

                    }}
                    style={{
                        border:'3px solid #2e58ec', backgroundColor:'#2e58ec', color:'white',
                        borderRadius:25, display:'flex',flexDirection:'row',
                        alignItems:'center', justifyContent:'center', height:50, marginTop:10, width:'60%'
                    }}>
                    <span>산재보험금 신청 ></span>
                </div>
            </div>

            <div style={{
                marginTop:50, display:'flex', flexDirection:"column", alignItems:'center',
                width:'100%', textAlign:'center', fontSize:22}}>
                <div
                    className={'_click'}
                    onClick={()=>{
                        console.log(userInfo)
                        if(userInfo['is_complete'] != 'Y'){
                            setModalApprove(true)
                        }else {
                            navigator('../lawList')
                        }

                    }}
                    style={{
                        border:'3px solid #2e58ec', backgroundColor:'white', color:'#2e58ec',
                        borderRadius:25, display:'flex',flexDirection:'row',
                        alignItems:'center', justifyContent:'center', height:50, marginTop:10, width:'60%'
                    }}>
                    <span>나의 진행상황 ></span>
                </div>
            </div>


            <Notice/>


            {/*{*/}
            {/*    userInfo &&*/}
            {/*    <Modal show={modalJoin} onHide={()=> {*/}
            {/*        setModalJoin(false);*/}
            {/*    }}>*/}
            {/*        <Modal.Header closeButton>*/}
            {/*            <Modal.Title style={{width:'100%', border:0}}>*/}
            {/*                <div style={{textAlign:'center', width:'100%'}}>*/}
            {/*                    <span>회원가입 인증 절차</span>*/}
            {/*                </div>*/}
            {/*            </Modal.Title>*/}
            {/*        </Modal.Header>*/}
            {/*        <Modal.Body style={{*/}
            {/*            padding:0,*/}
            {/*            display:'flex',*/}
            {/*            flexDirection:'row',*/}
            {/*            justifyContent:'center',*/}
            {/*            alignItems:'center'*/}
            {/*        }}>*/}
            {/*            <div style={{width:'100%', padding:20}}>*/}
            {/*                {*/}
            {/*                    renderApprove(userInfo)*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*        </Modal.Body>*/}
            {/*        <Modal.Footer>*/}
            {/*            <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>*/}
            {/*                <Button*/}
            {/*                    style={{width:width*0.4}}*/}
            {/*                    variant="primary" onClick={()=>{*/}
            {/*                    setModalJoin(false);*/}
            {/*                    // navigator('../')*/}
            {/*                }}>*/}
            {/*                    확인*/}
            {/*                </Button>*/}
            {/*            </div>*/}
            {/*        </Modal.Footer>*/}
            {/*    </Modal>*/}
            {/*}*/}
            <AlertWindow alertApprove={modalApprove} setModalApprove={setModalApprove}/>


        </div>
    )
}