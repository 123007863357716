import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {Navigate, Route, Routes, Outlet, useNavigate, useLocation} from "react-router-dom";
import Header from "./header"
import useWindowDimensions from "lib/useWindowDimensions";
import Footer from "./footer";


import Shipper from "./shipper";
import Trcuk from "./trcuk";
import {API_SERVER} from "devConfig";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "actions/userActions";
import Common from "./common";
export default function Main () {
    const {width, height} = useWindowDimensions();
    const userInfo = useSelector((state) => state.userReducer.userInfo)
    const navigator = useNavigate();
    const dispatch = useDispatch();

    const [mode, setMode]= useState('login');


    useEffect(()=>{
        console.log(userInfo)
        if(userInfo){
            setMode(userInfo['user_type'] == 1 ? 'truck' : 'shipper')
        } else{
            setMode('login')
        }
    },[userInfo])


    const renderMain=()=>{
        console.log(mode)
        switch (mode){
            // case 'truck':
            //     return(<Trcuk/>)
            // case 'shipper':
            //     return(<Shipper/>)
            case 'truck':
            case 'shipper':
                return(<Common />)
            default:
                return(
                    <div style={{width:'100%', height:height, display:'flex', flexDirection:'column',
                        justifyContent:'space-between', alignItems:'center',
                        }}>

                        <div style={{width:'100%'}}>
                            <Header />
                            <div style={{
                                width:'100%',
                                // borderRadius:15,
                                // backgroundColor: '#292b2c', //29location.pathname == '/dashboard' ? '#292b2c' : 'white',
                                backgroundColor:'white',
                                //   backgroundColor:'#F2F5F8',
                                overflowY:'auto',
                                minHeight:height*0.7
                            }}>
                                <Outlet/>
                            </div>
                        </div>

                        <div style={{width:'100%'}}>
                            <Footer />
                        </div>


                    </div>
                )

        }
        return
    }

    return renderMain()
}