import ACTION_TYPES from 'actions/actionsTypes';

const initUserState = {
    userInfo: null,
};

export function userReducer(state=initUserState, action) {

    var {userInfo} = state;

    switch(action.type) {

        case ACTION_TYPES.LOGOUT_USER:

            return {  ...state, userInfo : null };


        case ACTION_TYPES.LOGIN_USER:
            console.log(action['userInfo'])
            return {  ...state
                , userInfo : {...action['userInfo']}
            };


        default:
            return state;
    }
};
