import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {
    Button,
    Modal,
    Table,
    Nav,
    Navbar,
    Container,
    Alert,
    FloatingLabel,
    Form,
    Spinner,
    ListGroup
} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import {API_SERVER} from "devConfig";
import {loginUser} from "../actions/userActions";
import {useDispatch} from "react-redux";
import AlertWindow from "../lib/alertWindow";

export default function Join () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch()

    var termsRef = useRef();
    const [modalTerms, setModalTerms] = useState(false);
    const [modalPrivacy, setModalPrivacy] = useState(false)

    const [modalJoin, setModalJoin] = useState(false);
    const [chkFlag, setChkFlag] = useState(false);
    const [step, setStep] = useState(1);

    const [modalApprove, setModalApprove] = useState(false)

    const [joinInfo, setJoinInfo] = useState({
        phone : ['010', '', ''],
        pwd : '',
        pwd_chk : '',
        name : '',
        user_type : '',
        company_num : ['','',''],
        agreements : ''
    })
    const fields = [
        {key : 'phone', show_key : '휴대폰 번호'},
        {key : 'pwd', show_key : '비밀번호'},
        {key : 'pwd_chk', show_key : '비밀번호 재입력'},
        {key : 'name', show_key : '이름'},
        {key : 'user_type', show_key : '회원 구분'},
        {key : 'company_num', show_key : '사업자등록번호'},
    ]


    const renderApprove=(_info)=>{
        var result;
        switch (_info['user_type']){
            case '1':
                result =
                    <div style={{
                        borderRadius:20,
                        justifyContent:'center',
                        display:'flex', flexDirection:'row', flexWrap:"wrap",
                        marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                        <div style={{marginBottom:15, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:20, textDecoration:'underline'}}>차주회원 제출서류</span>
                        </div>

                        <div style={{textAlign:'left'}}>
                            <ListGroup as="ol" numbered>
                                {
                                    [
                                        '사업자등록증 사본',
                                        '차량등록증 사본',
                                        '화물운송자격증 사본',
                                        '협회 회원증 사본 (해당시)',
                                    ].map((item)=>{
                                        return(
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{item}</div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </div>
                        <div style={{marginTop:10, marginBottom:10, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:16, fontWeight:'bold', color:'#2e58ec'}}>*서류제출시 휴대폰 번호 알려주세요</span>
                        </div>
                    </div>
                break;
            case '2':
                result =
                    <div style={{
                        borderRadius:20,
                        justifyContent:'center',
                        display:'flex', flexDirection:'row', flexWrap:"wrap",
                        marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                        <div style={{marginBottom:15, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:20, textDecoration:'underline'}}>화주(주선사)회원 제출서류</span>
                        </div>

                        <div style={{textAlign:'left'}}>
                            <ListGroup as="ol" numbered>
                                    {
                                        [
                                            '사업자등록증 사본',
                                            '주선면허증 사본',
                                            '협회 회원증 사본 (해당시)',
                                        ].map((item)=>{
                                            return(
                                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{item}</div>
                                                    </div>
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                            </ListGroup>
                        </div>
                        <div style={{marginTop:10, marginBottom:10, display:'flex', flexDirection:'row'}}>
                            <span style={{fontSize:16, fontWeight:'bold', color:'#2e58ec'}}>*서류제출시 휴대폰 번호 알려주세요</span>
                        </div>
                    </div>
                break;
        }
        return(
            <div style={{width:'100%', textAlign:'center', fontWeight:'bold'}}>
                <div style={{padding:15}}>
                    <span style={{fontSize:20, fontWeight:'bold'}}>인증절차</span>
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                {
                    ['서류제출','검토','승인'].map((s,i)=>{
                        return(
                            <div style={{display:'flex', flexDirection:'row',
                                alignItems:'center',
                                justifyContent:'center',
                            }}>
                                {i > 0 && <div style={{marginLeft:2, marginRight:2}}><span>→</span></div>}
                                <div style={{
                                    display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
                                    height:40,
                                    width:width*0.23,
                                    border:'3px solid black', borderRadius:15}}>
                                    <span>{s}</span>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                <div>
                    {result}
                </div>

                <div style={{
                    borderRadius:20,
                    justifyContent:'center',
                    display:'flex', flexDirection:'row', flexWrap:"wrap",
                    marginTop:30, padding:'20px 15px 20px 15px', width:'100%', border:'3px solid lightgray'}}>

                    <div style={{marginBottom:15, display:'flex', flexDirection:'row'}}>
                        <span style={{fontSize:20, textDecoration:'underline'}}>서류 제출처</span>
                    </div>

                    <div style={{textAlign:'left'}}>
                        <ListGroup as="ol" numbered>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">이메일제출</div>
                                    call365call@gmail.com
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">문자 제출</div>
                                    010-5836-1550
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">우편 제출</div>
                                    서울시 서대문구 대신동 34-16 싸인트럭 주식회사
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>


                    <div
                        className={'_click'}
                        onClick={()=>{

                        }}
                        style={{
                            color :'black', backgroundColor:'#f7e111',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:10, width:'60%'
                        }}>
                        <span>카카오톡 문의</span>
                    </div>
                    <div
                        className={'_click'}
                        onClick={()=>{
                        }}
                        style={{
                            color :'white', backgroundColor:'#2e58ec',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:10, width:'60%'
                        }}>
                        <span>전화 문의</span>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div>
            {
                step == 1?
                    <div

                        style={{


                        justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                        width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
                        padding:height*0.04, fontSize:18
                    }}>
                        <div style={{width:'100%', textAlign:'center', marginBottom:20}}>
                    <span style={{fontSize:25}}>
                        회원가입
                    </span>
                        </div>
                        <div className={'joinForm'} style={{width:'100%'}}>

                            {
                                joinInfo &&
                                fields.map((sec)=>{
                                    var form;
                                    switch (sec['key']){
                                        case "company_num":
                                        case "phone":
                                            form =
                                                <div style={{
                                                    width:'100%', flex:1,
                                                    display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                    {
                                                        joinInfo[sec['key']].map((v, i)=>{
                                                            return(
                                                                <>
                                                                    <Form.Control
                                                                        style={{width: sec['key']=='phone' ? '22%' : '100%'}}
                                                                        // type={'number'}
                                                                        disabled={sec['key'] == 'phone' && chkFlag ? true : false}
                                                                        value={v}
                                                                        onChange={(e)=>{
                                                                            var value = e.target.value;
                                                                            value = value.replace(/[^0-9]/g, '')
                                                                            var _joinInfo = joinInfo;
                                                                            _joinInfo[sec['key']][i] = value;
                                                                            setJoinInfo({..._joinInfo})

                                                                        }}
                                                                        // disabled={true}
                                                                    />
                                                                    {
                                                                        i < 2 &&
                                                                        <div style={{marginLeft:3, marginRight:3}}>
                                                                            <span style={{fontWeight:'normal'}}>-</span>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        sec['key']=='phone' &&
                                                        <div
                                                            onClick={()=>{
                                                                var _joinInfo = JSON.parse(JSON.stringify(joinInfo));
                                                                // _joinInfo['phone'] = _joinInfo['phone'].join('');
                                                                if( _joinInfo['phone'][0] != '010'
                                                                    || _joinInfo['phone'][1].length != 4
                                                                    || _joinInfo['phone'][2].length != 4){
                                                                    alert.error('핸드폰 번호를 확인해주세요');
                                                                    return;
                                                                }else{
                                                                    fetch(API_SERVER + '/matching/chkPhone' ,{
                                                                        method: 'POST',
                                                                        headers: {
                                                                            Accept: 'application/json',
                                                                            'Content-Type': 'application/json'
                                                                        },
                                                                        body: JSON.stringify({
                                                                            phone:_joinInfo['phone'].join('-')
                                                                        })
                                                                    })
                                                                        .then(res => res.json())
                                                                        .then(json => {
                                                                            console.log(json)
                                                                            if(json['suc']){
                                                                                setChkFlag(true);
                                                                                alert.success('사용할 수 있는 핸드폰번호입니다')
                                                                            }else{
                                                                                alert.error('이미 가입된 번호입니다')
                                                                            }
                                                                        });
                                                                }
                                                            }}
                                                            className={'_click'}
                                                            style={{
                                                                display:'flex', flexGrow:1,
                                                                height:40,
                                                                backgroundColor:'#2e58ec', color:'white', borderRadius:15,
                                                                justifyContent:'center', alignItems:'center', marginLeft:5
                                                            }}>
                                                            <span style={{fontSize:14}}>중복확인</span>
                                                        </div>
                                                    }
                                                </div>
                                            break;
                                        case 'user_type':
                                            form =
                                                <div style={{
                                                    width:'100%', justifyContent:'space-evenly',
                                                    display:'flex', flexDirection:'row'}}>
                                                    {
                                                        [
                                                            {txt:'화물사업자', val : 1},
                                                            {txt:'변호사/로펌', val : 2},
                                                        ].map((v)=>{
                                                            return(
                                                                <Form.Group style={{display:'flex', flexDirection:'row'}}>

                                                                    <Form.Check
                                                                        id={'user_type_'+v['txt']}
                                                                        name={'user_type'}
                                                                        type={'radio'}
                                                                        // style={{marginRight: 15}}
                                                                        value={v['val']}
                                                                        // disabled={true}
                                                                        onChange={(e)=>{
                                                                            var value = e.target.value;
                                                                            setJoinInfo((prev) => ({
                                                                                ...prev,
                                                                                [sec['key']]: value
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <Form.Label style={{paddingLeft:15}} htmlFor={'user_type_'+v['txt']}>{v['txt']}</Form.Label>
                                                                </Form.Group>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            break;
                                        case 'pwd':
                                        case 'pwd_chk':
                                            form =
                                                <div style={{}}>
                                                    <Form.Control
                                                        type={'password'}
                                                        value={joinInfo[sec['key']] || null}
                                                        onChange={(e)=>{
                                                            var value = e.target.value;
                                                            setJoinInfo((prev) => ({
                                                                ...prev,
                                                                [sec['key']]: value
                                                            }))
                                                        }}
                                                        // disabled={true}
                                                    />
                                                </div>
                                            break;
                                        default:
                                            form =
                                                <div style={{}}>
                                                    <Form.Control
                                                        value={joinInfo[sec['key']] || null}
                                                        onChange={(e)=>{
                                                            var value = e.target.value;
                                                            setJoinInfo((prev) => ({
                                                                ...prev,
                                                                [sec['key']]: value
                                                            }))
                                                        }}
                                                        // disabled={true}
                                                    />
                                                </div>
                                    }
                                    return (
                                        <div style={{width:'100%', marginBottom:20}}>
                                            <div style={{padding:'5px 5px 5px 0px'}}>
                                                <span>{sec['show_key']}</span>
                                                <span style={{fontSize:12, color:'red'}}> *</span>
                                            </div>
                                            {
                                                form
                                            }
                                        </div>
                                    )

                                })
                            }

                            <div style={{

                                width: '100%',
                                marginTop: 40,
                                marginBottom:20,
                                fontSize:14,
                            }}>
                                <div className={'_click'} onClick={() => {
                                    termsRef.current.checked = !termsRef.current.checked;
                                }} style={{fontSize: 18, display: 'flex', flexDirection: 'row'}}>
                                    <Form.Check
                                        // disabled={true}
                                        ref={termsRef}
                                        style={{marginLeft: 10,}}
                                        type={'checkbox'}
                                        onClick={()=>{
                                            termsRef.current.checked = !termsRef.current.checked}
                                        }
                                    />
                                    <span style={{marginLeft: 10}}>모두 동의합니다</span>
                                </div>
                                <div
                                    className={'_click'}
                                    onClick={() => {
                                        setModalTerms(true)
                                    }}
                                    style={{
                                        marginTop:10,
                                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                    }}>
                                    <span>[필수] 서비스 이용약관</span>
                                    <span style={{fontWeight: 'normal', fontSize: 14, color: 'gray', textDecoration:'underline'}}>보기</span>
                                </div>
                                <div
                                    className={'_click'}
                                    onClick={() => {
                                        setModalPrivacy(true)
                                    }}
                                    style={{
                                        marginTop:10,
                                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                    }}>
                                    <span>[필수] 개인정보 수집 및 이용동의</span>
                                    <span style={{fontWeight: 'normal', fontSize: 14, color: 'gray', textDecoration:'underline'}}>보기</span>
                                </div>
                            </div>

                        </div>

                        <div style={{width:'100%', textAlign:'center', marginTop:20 }}>
                            <Button
                                onClick={()=>{


                                    var _joinInfo = JSON.parse(JSON.stringify(joinInfo));
                                    _joinInfo['agreements'] = termsRef.current.checked ? 1 : 0;


                                    // console.log(_joinInfo)

                                    for(var key of Object.keys(_joinInfo)){

                                        if(key == 'phone'){
                                            if(!chkFlag){
                                                alert.error('핸드폰 중복확인을 해주세요')
                                                return;
                                            }


                                            if(
                                                _joinInfo['phone'][0] != '010'
                                                || _joinInfo['phone'][1].length != 4
                                                || _joinInfo['phone'][2].length != 4
                                            ){
                                                var target = fields.find(x=>x.key == key);
                                                alert.error(target['show_key'] + '를 확인해주세요');
                                                return;
                                            }
                                            _joinInfo['phone'] = _joinInfo['phone'].join('-');
                                        }
                                        if(key == 'company_num'){
                                            if(
                                                _joinInfo['company_num'][0].length !== 3
                                                || _joinInfo['company_num'][1].length !== 2
                                                || _joinInfo['company_num'][2].length != 5
                                            ){
                                                var target = fields.find(x=>x.key == key);
                                                alert.error(target['show_key'] + '를 확인해주세요');
                                                return;
                                            }
                                            _joinInfo['company_num'] = _joinInfo['company_num'].join('-');
                                        }


                                        if(key == 'pwd' && _joinInfo['pwd'] !== _joinInfo['pwd_chk']){
                                            var target = fields.find(x=>x.key == key);
                                            alert.error(target['show_key'] + '를 확인해주세요');
                                            return;
                                        }

                                        if(_joinInfo[key] == '' || _joinInfo[key] == 0){
                                            var target = fields.find(x=>x.key == key);
                                            if(target){
                                                if(key == 'user_type' || key == 'name'){
                                                    alert.error(target['show_key'] + '을 확인해주세요');
                                                }else{
                                                    alert.error(target['show_key'] + '를 확인해주세요');
                                                }

                                            } else {
                                                alert.error('약관 동의를 확인해주세요');
                                            }
                                            return;
                                        }
                                    }
                                    delete _joinInfo['pwd_chk']
                                    console.log(_joinInfo)

                                    fetch(API_SERVER + '/matching/joinUser' ,{
                                        method: 'POST',
                                        headers: {
                                            Accept: 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            joinInfo:_joinInfo
                                        })
                                    })
                                        .then(res => res.json())
                                        .then(async json => {
                                            console.log(json)
                                            if(json['suc']){
                                                var userInfo = json['result'];
                                                console.log('login', json)
                                                await dispatch(loginUser(userInfo));
                                                setStep(2);
                                                var element = document.getElementById('mainDiv');
                                                element.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                });
                                                alert.success('회원가입이 완료되었습니다');
                                                navigator('/')
                                            }else{
                                                alert.error('서버와의 통신이 원활하지 않습니다. 잠시후 다시 시도해 주세요')
                                            }
                                        })

                                }}
                                style={{width:'80%', height:50, fontSize:20, fontWeight:'bold'}}>
                                가입하기
                            </Button>
                        </div>

                    </div>:
                    <div style={{
                        justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                        width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
                        fontSize:18
                    }}>
                        <div style={{
                            display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center',alignItems:'center',
                            height:height*0.6, width:'100%', textAlign:'center', marginBottom:20}}>
                            <div style={{width:'100%', textAlign:'center',display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center',alignItems:'center',}}>
                                    <span style={{fontSize:25}}>
                                        회원가입이{'\n'}완료되었습니다
                                    </span>


                                    <div
                                        className={'_click'}
                                        onClick={()=>{
                                            setModalApprove(true)
                                        }}
                                        style={{
                                            fontSize:22,
                                            color :'#2e58ec', border:'3px solid #2e58ec', backgroundColor:'white',
                                            borderRadius:25, display:'flex',flexDirection:'row',
                                            alignItems:'center', justifyContent:'center', height:50, marginTop:30,marginBottom:20, width:'80%'
                                        }}>
                                        <span>차주/화주 인증하기</span>
                                    </div>
                                    <div>
                                     <span style={{color:'gray'}}>
                                         인증 완료 후{'\n'}
                                         서비스 이용이 가능합니다
                                     </span>
                                    </div>
                            </div>
                        </div>

                    </div>
            }


            <Modal show={modalTerms} onHide={()=> {
                setModalTerms(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width:'100%', border:0}}>
                        <div style={{textAlign:'center', width:'100%'}}>
                            <span>이용 약관</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    padding:0,
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <div style={{width:'100%', padding:20}}>
                        <iframe
                            width={'100%'}
                            height={height*0.8}
                            src={API_SERVER + '/_terms'}></iframe>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={modalPrivacy} onHide={()=> {
                setModalPrivacy(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width:'100%', border:0}}>
                        <div style={{textAlign:'center', width:'100%'}}>
                            <span>개인정보 처리방침</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    padding:0,
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <div style={{width:'100%', padding:20}}>
                        <iframe
                            width={'100%'}
                            height={height*0.8}
                            src={API_SERVER + '/_privacy'}></iframe>
                    </div>
                </Modal.Body>
            </Modal>
            <AlertWindow alertApprove={modalApprove} setModalApprove={setModalApprove}/>

        </div>
    )
}