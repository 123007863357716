import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import {API_SERVER} from "devConfig";
import {useSelector} from "react-redux";
import {FaAngleLeft} from "react-icons/fa6";

export default function Law_list () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const [modalApprove, setModalApprove] = useState(false);

    const [fileModal, setFileModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFileIndex, setSelectedFileIndex] = useState(0);

    const [lawList, setLawList] = useState([]);

    useEffect(()=>{
        if(userInfo){
            fetch(API_SERVER + '/matching/getLawList' ,{
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userInfo
                })
            })
                .then(res => res.json())
                .then(json => {
                    console.log(json)
                    setLawList(json['result'])
                });
        }

    },[])

    const renderLawProg=(sec)=>{
        var status =
            sec['law_type'] == 1 ? ['서류검토중', '작성중', '완료'] : ['서류검토중', '작성중', '완료'];
        var result =
            status.map((s,i)=>{
                const _active = {
                    display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
                    height:50,
                    width: width*0.26,
                    border:'3px solid black', borderRadius:30
                }

                const _inactive = {
                    display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
                    height:50,
                    width: width*0.26,
                    border:'3px solid lightgray', borderRadius:30, color:'lightgray'
                }
                return(
                    <div style={{display:'flex', flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center', fontSize:16
                    }}>
                        {i > 0 && <div style={{marginLeft:2, marginRight:2}}><span> 〉 </span></div>}
                        {
                            i == 2 && sec['status'] == 0 ?
                                <div style={_active}>
                                    <span>취소</span>
                                </div>:
                                <div style={i == (sec['status']-1) ? _active : _inactive}>
                                    <span>{s}</span>
                                </div>
                        }
                    </div>
                )
            })
        return result;
    }

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {
            type: mime
        });
    }

    function downloadImg(imgSrc) {
        var image = new Image();
        image.crossOrigin = "anonymous";
        image.src = imgSrc;
        var fileName = image.src.split("/").pop();

        image.onload = function() {
            var canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            canvas.getContext('2d').drawImage(this, 0, 0);
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                window.navigator.msSaveBlob(dataURLtoBlob(canvas.toDataURL()), fileName);
            } else {
                var link = document.createElement('a');
                link.href = canvas.toDataURL();
                link.download = 'doc_'+fileName.split('_').pop();
                link.click();
            }
        };
    }

    const renderFileView = () =>{
        if(selectedRow['userDocs']){
            var img_paths = selectedRow['userDocs'].split(',');
            // for (var img of img_paths){
            //     imgs.push(
            //         <a
            //             style={{margin:10}}
            //             target={"_blank"} href={API_SERVER  + '/userFiles/'+selectedRow['user_id']+'/'+img}>
            //             <img
            //                 style={{width:70, height:70}}
            //                 src={API_SERVER  + '/userFiles/'+selectedRow['user_id']+'/'+img} />
            //         </a>
            //     )
            // }
        }

        const _active = {
            display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
            height:50,
            width: width/(img_paths.length + 1),
            border:'3px solid black', borderRadius:30
        }

        const _inactive = {
            display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
            height:50,
            width: width/(img_paths.length + 1),
            border:'3px solid lightgray', borderRadius:30, color:'lightgray'
        }

        var result  =
            <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                <div style={{
                    marginBottom:10,
                    width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    {
                        img_paths.map((img, i)=>{
                            return(
                                <div
                                className={'_click'}
                                onClick={()=>{
                                    setSelectedFileIndex(i)}
                                }
                                    style={{display:'flex', flexDirection:'row',
                                    alignItems:'center',
                                    justifyContent:'center', fontSize:18, fontWeight:'bold'
                                }}>
                                    <div style={i == selectedFileIndex ? _active : _inactive}>
                                        <span>서류 {i+1}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                 <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                     <div style={{position:'absolute', top:90, right:40}}>

                         <Button
                             style={{backgroundColor:'#2e58ec'}}
                             onClick={()=> {
                                 downloadImg(API_SERVER + '/userFiles/' + selectedRow['user_id'] + '/' + img_paths[selectedFileIndex])
                             }}
                         >
                                 다운로드
                         </Button>

                     </div>
                     <img
                         style={{width:'100%', height:'auto'}}
                         src={API_SERVER  + '/userFiles/'+selectedRow['user_id']+'/'+img_paths[selectedFileIndex]} />
                 </div>
            </div>

        return result;
    }
    return (
        <div>
            <div style={{
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
            }}>
                <div style={{
                    fontSize:20,
                    width:'100%', height:60, display:'flex', flexDirection:'row',
                    justifyContent:'space-between', alignItems:'center'
                }}>
                    <div
                        className={'_click'}
                        onClick={()=>{
                            navigator('/')
                        }}
                        style={{width:80,
                            textAlign:'left',
                            paddingLeft:15,
                        }}>
                        <FaAngleLeft/>
                    </div>
                    <span>나의 진행상황</span>
                    <div style={{width:80}}/>
                </div>



            <div style={{width:'90%', marginTop:10, marginBottom:30}}>
                {
                    lawList.length > 0 ?
                        lawList.map((sec, i)=>{
                            // console.log(sec)
                            return(
                                <div style={{
                                    fontSize:20,
                                    width:'100%', borderTop: i > 0 ? '1px solid black' : 0,
                                    paddingTop:25, paddingBottom:sec['status'] < 3 ? 25 : 5,
                                }}>
                                    <div style={{display:'flex', flexDirection:'row',}}>
                                        <div><span>{moment(sec['created_at']).format('YYYY-MM-DD')}</span></div>
                                        <div style={{marginLeft:10}}><span>{sec['law_type'] == 1 ? '내용증명 ' : '산재보험금 '}신청</span></div>
                                    </div>
                                    <div>
                                        <span>접수번호 {sec['_id'].toString().padStart(5,'0')}</span>

                                    </div>

                                    <div style={{
                                        marginTop:25, marginBottom:5,
                                        display:'flex', flexDirection:'row',
                                        width:'100%', justifyContent:'center'
                                    }}>
                                        {renderLawProg(sec)}
                                    </div>

                                    {
                                        sec['status'] == 3 &&
                                        <div
                                        onClick={()=>{

                                            fetch(API_SERVER + '/admin/getLawListById/'+sec['_id'])
                                                .then(res => res.json())
                                                .then(async json => {
                                                    if(json['suc']){
                                                        console.log(json)
                                                        if(json['suc']){
                                                            if(Object.keys(json['result']).includes('userDocs')){
                                                                setSelectedRow(json['result'])
                                                                setFileModal(true)
                                                            }else{
                                                                window.alert('곧 서류 업로드 예정입니다')
                                                            }
                                                        }
                                                    }
                                                });
                                        }}
                                            style={{width:'100%', display:'flex', flexDirection:'row'
                                            ,justifyContent:'flex-end', paddingTop:10, fontSize:18,
                                        }}>
                                            <span style={{
                                                textDecoration:'underline', color:'gray'}}>상세보기 〉</span>
                                        </div>
                                    }
                                    {
                                        sec['status'] == 0 &&
                                        <div style={{width:'100%', display:'flex', flexDirection:'row'
                                            ,justifyContent:'flex-end', paddingTop:10, fontSize:18,
                                        }}>
                                            <span style={{
                                                color:'gray'}}>{sec['cancel_reason']}</span>
                                        </div>
                                    }
                                </div>
                            )
                        }):
                        <div>
                            <span>신청하신 법률서비스가 없습니다</span>
                        </div>
                }
            </div>

            </div>

            {
                selectedRow &&
                <Modal show={fileModal} onHide={()=> {
                    setFileModal(false);
                    setSelectedRow(null);
                    setSelectedFileIndex(0)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{width:'100%', border:0}}>
                            <div style={{textAlign:'center', width:'100%'}}>
                                <span>완료 서류</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{
                        padding:0,
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center'
                    }}>
                        <div style={{width:'100%', padding:20}}>
                            {
                                renderFileView()
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <Button
                                style={{width:width*0.4, backgroundColor:'#2e58ec'}}
                                variant="primary" onClick={()=>{
                                setFileModal(false)
                                // setModalJoin(false);
                                // navigator('../')
                            }}>
                                확인
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            }


        </div>
    )
}