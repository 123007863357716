import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'

import {BrowserRouter, MemoryRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AlertTemplate from 'react-alert-template-basic'


import { legacy_createStore as createStore } from 'redux';
import { Provider  } from 'react-redux';
import { appReducer, persistedReducer } from 'reducers';
import { persistStore } from 'redux-persist';
import { PersistGate } from "redux-persist/integration/react";
const store = createStore(persistedReducer);
const persistor = persistStore(store);

const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    // position: positions.TOP_CENTER,
    timeout: 2000,
    // offset: '50px',
    // zIndex:1051,
    // zIndex : 100,
    // you can also just use 'scale'
    transition: transitions.FADE
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    {/*<MemoryRouter>*/}
                    <App />
                    {/*</MemoryRouter>*/}
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </AlertProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
