import ACTION_TYPES from 'actions/actionsTypes';

export const loginUser = (userInfo) => {
    return { type: ACTION_TYPES.LOGIN_USER, userInfo };
}

export const logoutUser = () => {
    return { type: ACTION_TYPES.LOGOUT_USER };
}

