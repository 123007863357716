import './App.css';
import ChannelService from "./lib/channelService";
import React, {useEffect, Suspense, useState} from "react";
import channelService from "./lib/channelService";
import useWindowDimensions from "./lib/useWindowDimensions";
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import Header from "./component/header";
import Home from "./component/home";
import Footer from "./component/footer";
import Login from "./component/login";
import Join from "./component/join";
import Shipper from "./component/shipper";
import Truck from "./component/trcuk";
import {useSelector} from "react-redux";
import Service from "./component/service";
import Contact from "./component/contact";

import Account from "./component/account";
import Main from "./component";
import Common from "./component/common";
import CommonMain from "./component/common/main";
import Law_reg from "./component/common/law_reg";
import Law_list from "./component/common/law_list";

function App() {
  const { width, height } = useWindowDimensions();

  const location = useLocation();
  const [mode, setMode] = useState(null);
  const userInfo = useSelector((state) => state.userReducer.userInfo);


  useEffect(()=>{
    console.log(width)
    console.log(location)
  },[width])



  const option =  {
    "pluginKey": "a4c09ee4-811a-4f20-83c2-98d138aec8f3",
    "customLauncherSelector": ".button",
    "hideChannelButtonOnBoot": true,
    // "memberId": 'yourMemberId',
    // "profile": {
    //   "name": "yourName",
    //   "mobileNumber": "010-xxxx-xxxx",
    //   "email": "yourEmail@gmail.com",
    // }
  }
  useEffect(()=>{
    channelService.boot(option);
    channelService.showChannelButton()
  },[])

  useEffect(()=>{
    console.log(userInfo)
    // if(userInfo){
    //   setMode(userInfo['user_type'] == 1 ? 'truck' : 'shipper')
    // } else{
    //   setMode(null)
    // }
  },[userInfo])

  // const renderMain=()=>{
  //   console.log(mode)
  //   switch (mode){
  //     case 'truck':
  //       return(<Truck/>)
  //     case 'shipper':
  //       return(<Shipper/>)
  //     default:
  //       return(
  //
  //       )
  //
  //   }
  //   return
  // }
  return (
      <div style={{position:'fixed', width:'100vw', height:'100vh'}}>
        <div id={'mainDiv'} style={{width:'100%', height:'100%', overflow:'auto',display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center'}}>
          <div style={{width:width, borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray'}}>

                  <Routes>
                    <Route path={'/'} element={<Main />}>
                      <Route path={''} element={<Navigate to="./home" replace />}/>
                      <Route path={'home'} element={<Home />}/>
                      <Route path={'join'} element={<Join />}/>
                      <Route path={'service'} element={<Service />}/>
                      <Route path={'contact'} element={<Contact />}/>
                      <Route path={'account'} element={<Account />}/>

                    </Route>
                    <Route path={'/common'} element={<Common />}>
                      <Route path={''} element={<Navigate to="./main" replace />}/>
                      <Route path={'main'} element={<CommonMain />}/>
                      <Route path={'lawReg/:_type'} element={<Law_reg />}/>
                      <Route path={'lawList'} element={<Law_list />}/>

                      {/*  <Route path={'law_reg'} element={<Tr_law_reg />}/>*/}
                      {/*  <Route path="law_reg/:_type" element={<Tr_law_prog/>}/>*/}

                      {/*  <Route index path={'match'} element={<Tr_match_main />}/>*/}
                      {/*  <Route index path={'profile'} element={<Tr_profile />}/>*/}
                    </Route>



                    {/*<Route path={'/shipper'} element={<Shipper />}>*/}
                    {/*  <Route path={''} element={<Navigate to="./main" replace />}/>*/}
                    {/*  <Route path={'main'} element={<Sp_main />}/>*/}

                    {/*  <Route path={'law_list'} element={<Sp_law_list />}/>*/}

                    {/*  <Route index path={'law_reg'} element={<Sp_law_reg />}/>*/}
                    {/*  <Route path="law_reg/:_type" element={<Sp_law_prog />} />*/}

                    {/*  <Route index path={'match'} element={<Sp_match_main />}/>*/}
                    {/*  <Route index path={'match/my_list'} element={<Sp_my_list />}/>*/}
                    {/*  <Route index path={'match/truck_list'} element={<Sp_truck_list />}/>*/}
                    {/*</Route>*/}



                    <Route path="/*" element={<div style={{width:'100%', height:'100%' , display:'flex', justifyContent:'center', alignItems:'center'}}>Not found</div>} />
                  </Routes>
          </div>
        </div>
      </div>
  );
}

export default App;
