import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import Login from "./login";
import {useSelector} from "react-redux";

export default function Home () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const userInfo = useSelector((state) => state.userReducer.userInfo);

    useEffect(()=>{
        console.log(userInfo)
        if(userInfo){
            navigator('/common')
            // userInfo['user_type'] == 1 ? navigator('/truck') : navigator('/shipper')
        }

    },[userInfo])
    return (
        <div>
            <div style={{
                textAlign:'center',
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
                padding:height*0.03,
            }}>
                <div style={{width:'100%'}}>
                    <span style={{fontSize:30}}>화물사업자를 위한{'\n'}법률복지 서비스</span>
                </div>
            </div>
            <Login/>
        </div>
    )
}