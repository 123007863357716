import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {
    Button,
    Modal,
    Table,
    Nav,
    Navbar,
    Container,
    Alert,
    FloatingLabel,
    Form,
    Spinner,
    ListGroup
} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import {API_SERVER} from "../../devConfig";
import {useSelector} from "react-redux";



export default function Law_reg () {
    const params = useParams();
    const alert = useAlert();
    const _type = parseInt(params['_type']);

    const [chkModal, setChkModal] = useState(false);

    const [step, setStep] = useState(1);
    const [contact, setContact] = useState('');


    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const userInfo = useSelector((state) => state.userReducer.userInfo);


    const sendLawReg=()=>{
        fetch(API_SERVER + '/matching/newLaw' ,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userInfo,
                contact,
                law_type : _type
            })
        })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                if(json['suc']){
                    setChkModal(true);
                }else{
                    alert.error('신청에 실패했습니다. 관리자에게 문의해주세요')
                }
            });
    }

    const renderPaper=()=>{
        console.log(_type)
        switch (_type){
            case 1:
                return(
                    <div style={{
                        border:'1px solid black',
                        backgroundColor:'#f5f5f5',
                        fontSize:18,
                        marginTop:20, borderRadius:10,
                        width:'90%', display:'flex', flexDirection:"column",
                        alignItems:"center",
                        padding:10, fontWeight:"bold", textAlign:"center"
                    }}>
                        <div>
                            <span>아래 서류를 미리 준비하면{'\n'}빠른 진행이 가능해요</span>
                        </div>

                        <div  style={{marginTop:20, marginBottom:20, fontSize:18}}>
                            <span>[ 필요 서류 ]</span>
                        </div>

                        <div style={{width:'100%'}}>
                            <ListGroup as="ol" numbered>
                                {
                                    [
                                        ['차주 정보','신분증, 사업자등록증'],
                                        ['화주 정보','사업자등록증, 전화번호'],
                                        ['운임 정보','세금계산서'],
                                    ].map((item)=>{
                                        return(
                                            <ListGroup.Item as="li" style={{
                                                textAlign:"left",
                                                display:'flex', flexDirection:"row", justifyContent:'flex-start'}}>
                                                <div>
                                                    <div style={{marginLeft:5}}>{item[0]}:</div>
                                                    <div style={{marginLeft:5}}>{item[1]}</div>
                                                </div>

                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </div>
                    </div>
                )
            case 2:
                return(
                    <div style={{
                        border:'1px solid black',
                        backgroundColor:'#f5f5f5',
                        fontSize:18,
                        marginTop:20, borderRadius:10,
                        width:'90%', display:'flex', flexDirection:"column",
                        alignItems:"center",
                        padding:10, fontWeight:"bold", textAlign:"center"
                    }}>
                        <div style={{whiteSpace:"pre-wrap"}}>
                            <span>아래 서류를 미리 준비하면{'\n'}빠른 진행이 가능해요</span>
                        </div>

                        <div  style={{marginTop:20, marginBottom:20, fontSize:18}}>
                            <span>[ 필요 서류 ]</span>
                        </div>
                    <div style={{width:'100%'}}>
                        <ListGroup as="ol" numbered>
                            {
                                [
                                    ['차주 정보','-신분증, 사업자등록증'],
                                    ['화주 정보','-사고당일 세금 계산서\n-화주 또는 운송사 이름/전화번호/주소'],
                                    ['재해정보','-병원 진단서\n-사고당일 주고받은 업무 문자'],
                                ].map((item)=>{
                                    return(
                                        <ListGroup.Item as="li" style={{
                                            textAlign:"left",
                                            whiteSpace:"pre-wrap",
                                            display:'flex', flexDirection:"row", justifyContent:'flex-start'}}>
                                            <div>
                                                <div>{item[0]}:</div>
                                                {item[1]}
                                            </div>

                                        </ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                    </div>
                    </div>
                )
        }
    }
    return (
        <div style={{
            marginTop:50,
            textAlign:'center',
            justifyContent:'center', alignItems:'center', flexWrap:'wrap',
            width:'100%',  display:'flex', flexDirection:'row', fontWeight:'bold', whiteSpace:'pre-wrap',
            paddingBottom:height*0.1
        }}>

            <div style={{
                width:'95%',
                display:'flex', flexDirection:'row',
                alignItems:'center',
                justifyContent:'center', flexWrap:"wrap",
            }}>
                {
                    _type == 1 ?
                        <span style={{fontSize:20}}>내용증명 신청절차</span>
                        :
                        <span style={{fontSize:20}}>산재보험금 신청절차</span>
                }

                <div style={{marginTop:10,
                    width:'100%', display:'flex', flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'center'
                }}>
                    {
                        ['신청','서류검토','작성','발송'].map((s,i)=>{
                            return(
                                <div style={{display:'flex', flexDirection:'row',
                                    alignItems:'center',
                                    justifyContent:'center', fontSize:16,
                                }}>
                                    {i > 0 && <div style={{marginLeft:2, marginRight:2}}><span> > </span></div>}
                                    <div style={{
                                        display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center',
                                        height:40,
                                        // minWidth:width*0.2,
                                        paddingLeft:10,
                                        paddingRight:10,
                                        border:'3px solid black', borderRadius:25}}>
                                        <span>{s}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{marginTop:30,
                    width:'95%', display:'flex', flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    fontSize:18,
                    border:'5px solid lightgray',
                    borderRadius:15,
                    padding:20,
                }}>
                    <div style={{marginBottom:10}}>
                        <span>연락 받으실 전화번호</span>
                    </div>
                    <div style={{
                        width:'95%',
                        border:'2px solid lightgray', marginBottom:10}}>
                        <Form.Control
                            className={'_under'}
                            value={contact || ''}
                            onChange={(e)=>{
                                var value = e.target.value;
                                if(value.length < 14)
                                    setContact(value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`))
                            }}
                            // disabled={true}
                        />
                    </div>

                    <div style={{
                        fontSize:16,
                        width:'95%', display:'flex', flexDirection:"row", justifyContent:"space-between"}}>
                        <div
                            className={'_click'}
                            onClick={()=>navigator('../main')}
                            style={{
                            border:'1px solid #2e58ec', backgroundColor:'white', color:'#2e58ec',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:10,
                            width:'25%'
                        }}>
                            <span>취소</span>
                        </div>
                        <div
                            className={'_click'}
                            onClick={()=> {
                                var _contact = contact.split('-');
                                // console.log(_contact)
                                if(
                                    _contact.length !== 3
                                    || _contact[0] != '010'
                                    || !(_contact[1].length == 3 || _contact[1].length == 4)
                                    || _contact[2].length != 4){
                                    alert.error('핸드폰 번호를 확인해주세요');
                                    return;
                                }
                                sendLawReg()
                            }}
                            style={{
                            border:'1px solid #2e58ec', backgroundColor:'#2e58ec', color:'white',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:40, marginTop:10,
                            width:'70%'
                        }}>
                            <span>신청하기</span>
                        </div>
                    </div>
                    {
                        renderPaper()
                    }
                </div>
            </div>

            <Modal
                className={'centerModal'}
                // size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={chkModal} onHide={()=> {
                setChkModal(false);
            }}>
                <Modal.Body style={{
                    padding:0,
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <div style={{width:'100%',
                        textAlign:"center",
                        fontSize:18, fontWeight:"bold",
                    display:"flex", flexDirection:"column", alignItems:'center',
                        padding:20}}>
                        <div>
                            <span style={{whiteSpace:'pre-wrap'}}>
                                {_type == 1 ? '내용증명 ' : '산재보험금 '}
                                신청이 완료되었습니다.{'\n'}입력하신 전화번호로 연락드리겠습니다.
                            </span>
                        </div>
                        {
                            renderPaper()
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        <div
                            style={{
                                border:'1px solid #2e58ec', backgroundColor:'#2e58ec', color:'white',
                                borderRadius:25, display:'flex',flexDirection:'row',
                                alignItems:'center', justifyContent:'center', height:40, marginTop:10,
                                width:'60%'
                                }}
                            variant="primary" onClick={()=>{
                            setChkModal(false);
                            navigator('../main')
                        }}>
                            확인
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

        </div>
    )
}