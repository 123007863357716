import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session'

import {userReducer} from './userReducer';
import {dictReducer} from "./dictReducer";

const persistConfig = {
    key: "root",
    // storage,
    storage : storageSession,
    whitelist: ["userReducer"]
    // blacklist : []
};

export const appReducer = combineReducers({
    userReducer,
    dictReducer
});

export const persistedReducer = persistReducer(persistConfig, appReducer);
