import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import {API_SERVER} from "devConfig";
import {loginUser, logoutUser} from "actions/userActions";
import {useDispatch, useSelector} from "react-redux";
export default function Notice () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const alert=  useAlert();

    const [noticeList, setNoticeList] = useState([]);
    const [selectedNotice, setSelectedNotice] = useState(null);
    const [noticeModal, setNoticeModal] = useState(false);

    const [offSet, setOffset] = useState(4);


    useEffect(()=>{

        fetch(API_SERVER + '/matching/getNoticeList')
            .then(res => res.json())
            .then(async json => {
                if(json['suc']){
                    setNoticeList([...json['result']])
                }
            });
        // setNoticeList([
        //     {title : '서비스 소개', created_at : '2020-01-18'},
        //     {title : '산재보험 정책개선안 안내', created_at : '2020-01-18'},
        //     {title : '신규 협약 안내', created_at : '2020-01-18'},
        //     {title : '추석 휴무일 안내', created_at : '2020-01-18'},
        //     {title : '서비스 소개', created_at : '2020-01-18'},
        //     {title : '서비스 소개', created_at : '2020-01-10'},
        // ])
    },[])



    useEffect(()=>{
        if(selectedNotice){
            setNoticeModal(true);
        }else{
            setNoticeModal(false);
        }

    },[selectedNotice])


    return (
        <div>
            <div style={{
                marginTop:30,
                textAlign:'center',
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
                // padding:height*0.05
            }}>
                <div style={{width:'100%'}}>
                    <div
                        style={{
                            marginBottom:height*0.1,
                            marginTop:height*0.1,
                            textAlign:'center',
                            justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                            width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',}}>

                        <div style={{width:'100%', textAlign:'center'}}>
                            <span style={{fontSize:25}}>
                                공지사항
                            </span>
                        </div>
                        <div style={{
                            margin:'35px 0px',
                            width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center'}}>
                            {
                                noticeList.length > 0 &&
                                noticeList
                                    .slice(0, offSet)
                                    .map((item,i )=>{
                                        // console.log(item)
                                        return(
                                            <div
                                                className={'_click'}
                                                onClick={async ()=>{
                                                    // console.log(item)
                                                    setSelectedNotice(item);
                                                }}
                                                style={{
                                                    borderTop: i == 0 ? '1px solid lightgray' : 0,
                                                    padding:'15px 0px', borderBottom:'1px solid lightgray',
                                                    width:'80%',
                                                    display:'flex', flexDirection:'row',
                                                    justifyContent:'space-between',
                                                    alignItems:'center',
                                                    fontSize:18
                                                }}>
                                                <div style={{ width:'60%', textAlign:'left'}}>
                                                    <span>{item['title']}</span><br/>
                                                    <span style={{color:'gray', fontSize:16}}>{moment(item['created_at']).format('YYYY.MM.DD')}</span>
                                                </div>
                                                <div style={{display:'flex', flexDirection:'row'}}>
                                                    <div>
                                                        <img src={require('img/arrow.png')} height={40} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                        <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                            <div
                                className={'_click'}
                                onClick={()=>{
                                    // setHistoryModalOpen(true)
                                    setOffset((prev)=>prev + 4);
                                }}
                                style={{
                                    fontSize:22,
                                    color :'#2e58ec', border:'3px solid #2e58ec', backgroundColor:'white',
                                    borderRadius:25, display:'flex',flexDirection:'row',
                                    alignItems:'center', justifyContent:'center', height:50, marginTop:20, width:'80%'
                                }}>
                                <span>더보기</span>
                            </div>
                        </div>
                    </div>

                    {
                        selectedNotice &&
                        <Modal show={noticeModal} onHide={()=> {
                            setNoticeModal(false);
                            setSelectedNotice(false);
                        }}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{width:'100%', border:0}}>
                                    <div style={{textAlign:'center', width:'100%'}}>
                                        <span>공지사항</span>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{padding:0}}>
                                <div style={{
                                    padding:'0px 30px'
                                }}>
                                    <div style={{width:'100%', textAlign:'right', color:'gray'}}>
                                        <span>
                                            {moment(selectedNotice['created_at']).format('YYYY-MM-DD hh:mm')}
                                            <br/><br/>
                                        </span>
                                    </div>
                                        <div style={{marginBottom:0}}>
                                            <span style={{fontSize:20, fontWeight:"bold"}}>{selectedNotice['title']}</span>
                                        </div>
                                    <div style={{lineHeight:1.7}}>
                                        <span style={{whiteSpace:'pre-wrap'}}>
                                            {selectedNotice['note']}
                                        </span>
                                    </div>

                                    {
                                        selectedNotice['file_path'] &&
                                        <div style={{marginTop:10}}>
                                            <img
                                                style={{width:'100%'}}
                                                src={API_SERVER  + '/noticeFile/'+selectedNotice['file_path']} />
                                        </div>

                                    }


                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <Button style={{backgroundColor:'#2e58ec'}} onClick={()=>{
                                        setNoticeModal(false);
                                        setSelectedNotice(false);
                                    }}>
                                        닫기
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    }

                </div>
            </div>


        </div>
    )
}