import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {DropdownButton, Dropdown, ListGroup, Modal, Button} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "actions/userActions";
import useWindowDimensions from "lib/useWindowDimensions";
import AlertWindow from "../lib/alertWindow";

export default function Header () {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const {width, height} = useWindowDimensions();

    const [modalOpen, setModalOpen] = useState(false);
    const [myModalOpen, setMyModalOpen] = useState(false);
    const [modalApprove, setModalApprove] = useState(false)

    const userInfo = useSelector((state) => state.userReducer.userInfo);

    return (
        <div style={{
            paddingLeft:10, paddingRight:10,
            }}>
            <div style={{

                height:60, alignItems:'center',
                width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <div
                    className={'_click'}
                    onClick={()=>{
                        if(userInfo){
                            navigator('/common')
                        } else{
                            navigator('/')
                        }

                    }}
                    style={{width:'60%', paddingLeft:5}}>
                    <img src={require('img/t119.png')} height={30} />
                </div>

                {
                    userInfo &&
                    <div
                        className={'_click'}
                        onClick={()=>setModalOpen(true)}
                        style={{
                            display:'flex',
                            justifyContent:'center', alignItems:'center',
                            backgroundColor:'#2e58ec', color:'white', fontSize:16, height:35, width:70, borderRadius:26}}>
                        <span>메뉴</span>
                    </div>
                }

            </div>
            {
                userInfo &&
                <div style={{
                    fontSize:15,
                    width:'100%', display:'flex', flexDirection:'row',justifyContent:"flex-end", alignItems:'center'}}>
                    <span style={{textDecorationLine:"underline", color:'gray'}}>{userInfo['phone']} 님</span>
                </div>
            }


            {
                userInfo &&
                <Modal show={modalOpen} onHide={()=> {
                    setModalOpen(false);
                }}>
                    <Modal.Header>
                        <Modal.Title style={{width:'100%', border:0}}>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <div className={'_click'} onClick={()=>setModalOpen(false)}>
                                    <img src={require('img/cancel.png')} height={25} />
                                </div>
                                <div>
                                    <span style={{color:'gray', textDecoration:'underline'}}>{userInfo['phone']} 님</span>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{
                        padding:0,
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        // alignItems:'center',
                        fontWeight:'bold',
                        minHeight:height*0.8
                    }}>
                        <div style={{width:'100%', padding:20}}>
                            {
                                [
                                    {key : 'home', showKey : '홈으로'},
                                    {key : 'common/lawList', showKey : '나의 진행상황'},
                                    {key : 'account', showKey : '내 계정정보'},
                                    {key : 'service', showKey : '서비스 소개'},
                                    {key : 'contact', showKey : '고객센터'},
                                    {key : 'logout', showKey : '로그아웃'},
                                ].map((sec)=>{
                                    return(
                                        <div
                                            onClick={()=>{
                                                switch (sec['key']){
                                                    case 'common/lawList':
                                                        if(userInfo['is_complete'] != 'Y'){
                                                            setModalApprove(true)
                                                        }else {
                                                            navigator('/'+sec['key'])
                                                        }
                                                        break;
                                                    case 'logout':
                                                        dispatch(logoutUser());
                                                        navigator('/')
                                                        break;
                                                    default:
                                                        navigator('/'+sec['key'])
                                                        break;
                                                }

                                                // if(sec['key'] == 'logout'){
                                                //     dispatch(logoutUser());
                                                //     navigator('/')
                                                // }else{
                                                //     if(sec['key'] == 'common/lawList'){
                                                //         if(userInfo['is_complete'] != 'Y'){
                                                //             setModalApprove(true)
                                                //         }else {
                                                //             navigator('/'+sec['key'])
                                                //         }
                                                //     }
                                                // }
                                            }}
                                        className={'_click'}
                                            style={{paddingTop:22, paddingBottom:7, borderBottom:'2px solid gray'}}>
                                            <span style={{fontSize:18}}>
                                                {sec['showKey']}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>
                </Modal>

            }
            <AlertWindow alertApprove={modalApprove} setModalApprove={setModalApprove}/>

        </div>
    )
}