import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import useWindowDimensions from "lib/useWindowDimensions";

export default function Footer () {
    const {width, height} = useWindowDimensions();
    return (
        <div style={{
            justifyContent:'center',
            width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
            {/*<div style={{*/}
            {/*    width:width,*/}
            {/*    display:'flex', flexDirection:'row', justifyContent:'space-around'}}>*/}
            {/*    {*/}
            {/*        [require('img/asso1.png'),require('img/asso2.png'),require('img/asso3.png')].map((img)=>{*/}
            {/*            return(*/}
            {/*                <img src={img} height={60} />*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
                <div style={{width:'100%', paddingBottom:70, backgroundColor:'#f5f5f5',
                    display:'flex', flexDirection:'row', justifyContent:'center'
                }}>
                    <div style={{width:width*0.85,}}>
                    <div style={{paddingTop:50, paddingBottom:10}}>
                        <span style={{fontSize:18, fontWeight:'bold'}}>트럭119</span>
                    </div>
                        <div
                            className={'_click'}
                            onClick={()=> document.location.href='http://pf.kakao.com/_fVHrG/chat'}

                            style={{
                                fontSize:22, fontWeight:'bold',
                                color :'black', border:'3px solid black', backgroundColor:'#f7e111',
                                borderRadius:25, display:'flex',flexDirection:'row',
                                alignItems:'center', justifyContent:'center', height:50, marginBottom:10, width:'100%'
                            }}>
                            <span>카카오톡 문의</span>
                        </div>
                        {/*<div style={{paddingTop:20, paddingBottom:10}}>*/}
                        {/*    <span style={{fontSize:18, fontWeight:'bold'}}>적재물 책임보험: 롯데손해보험(주)</span>*/}
                        {/*</div>*/}
                    {
                        [
                            // '사업자등록번호: 329-86-02903',
                            // '화물운송주선사업자: 제 369 호',
                            // '대표: 금현경',
                            '주소: 서울 서대문구 성산로 512-42 이화여대 이룸센터',
                            '이메일: truck119kr@gmail.com',
                            '전화: 070-7720-1551',]
                            .map((txt)=>{
                                return(
                                    <div style={{}}>
                                        <span style={{fontSize:12}}>{txt}</span>
                                    </div>
                                )
                            })
                    }
                        {/*<div style={{paddingTop:20, paddingBottom:10}}>*/}
                        {/*    <span style={{fontSize:18, fontWeight:'bold'}}>고객센터: 1551-1550</span>*/}
                        {/*</div>*/}
                </div>
            </div>
        </div>
    )
}