import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import {API_SERVER} from "devConfig";
import {loginUser, logoutUser} from "actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import Notice from "./notice";
export default function Login () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const alert=  useAlert();


    const [noticeList, setNoticeList] = useState([]);
    const [selectedNotice, setSelectedNotice] = useState(null);
    const [noticeModal, setNoticeModal] = useState(false);

    // const userInfo = useSelector((state) => state.userReducer.userInfo);


    const [loginInfo, setLoginInfo] = useState({
        phone : '',
        pwd : '',
    })

    useEffect(()=>{
        setNoticeList([
            {title : '서비스 소개', created_at : '2020-01-18'},
            {title : '산재보험 정책개선안 안내', created_at : '2020-01-18'},
            {title : '신규 협약 안내', created_at : '2020-01-18'},
            {title : '추석 휴무일 안내', created_at : '2020-01-18'},
            {title : '서비스 소개', created_at : '2020-01-18'},
            {title : '서비스 소개', created_at : '2020-01-10'},
        ])
    },[])

    useEffect(()=>{
        if(selectedNotice){
            setNoticeModal(true);
        }else{
            setNoticeModal(false);
        }

    },[selectedNotice])

    // useEffect(()=>{
    //     dispatch(logoutUser());
    // },[])



    return (
        <div>
            <div style={{
                marginTop:30,
                textAlign:'center',
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
                // padding:height*0.05
            }}>

                <div style={{width:'80%', marginTop:10, textAlign:'left', fontSize:22}}>
                    <div style={{width:'100%', marginTop:10}}>
                        <div style={{marginBottom:5}}>
                            <span>휴대폰 번호</span>
                        </div>
                        <div style={{borderBottom:'1px solid lightgray'}}>
                            <Form.Control
                                className={'_under'}
                                value={loginInfo['phone']}
                                onChange={(e)=>{
                                    var value = e.target.value;
                                    if(value.length < 14)
                                        setLoginInfo((prev)=>{
                                            return{
                                                ...prev,
                                                phone : value.replace(/[^0-9]/g, '')
                                                    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                                            }
                                        })
                                }}
                                // disabled={true}
                            />
                        </div>
                    </div>
                    <div style={{width:'100%', marginTop:10}}>
                        <div style={{marginTop:5, marginBottom:5}}>
                            <span>비밀번호</span>
                        </div>
                        <div style={{borderBottom:'1px solid lightgray'}}>
                            <Form.Control
                                className={'_under'}
                                type={'password'}
                                value={loginInfo['pwd']}
                                onChange={(e)=>{
                                    var value = e.target.value;
                                    setLoginInfo((prev)=>{
                                        return{
                                            ...prev,
                                            pwd : value
                                        }
                                    })
                                }}
                                // disabled={true}
                            />
                        </div>
                    </div>
                </div>



                <div
                    className={'_click'}
                    onClick={()=>{
                      var _loginInfo = JSON.parse(JSON.stringify(loginInfo));
                      if(_loginInfo['phone'] == ''){
                          alert.error('휴대폰 번호를 입력해주세요');
                          return
                      }else if(_loginInfo['pwd'] == ''){
                          alert.error('비밀번호를 입력해주세요');
                          return;
                      }
                      //   var regex = /[^0-9]/g;
                      // _loginInfo['phone'] = _loginInfo['phone'].replace(regex, "");


                        fetch(API_SERVER + '/matching/loginUser' ,{
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                ..._loginInfo
                            })
                        })
                            .then(res => res.json())
                            .then(async json => {
                                if(json['suc']){
                                    var userInfo = json['result'];
                                    console.log('login', json)
                                    await dispatch(loginUser(userInfo));
                                    // console.log(userInfo)
                                    // var user_type = userInfo['user_type'] == 1 ? '차주' : '화주';
                                    // userInfo['user_type'] == 1 ? navigator('/truck') : navigator('/shipper')
                                    navigator('/common')
                                    alert.success('안녕하세요. ' + userInfo['phone'] + '님');
                                }else{
                                    if(json['err'] == 1){
                                        alert.error('비밀번호 오류')
                                    }else{
                                        alert.error('일치하는 회원정보가 없습니다')
                                    }

                                }
                            })

                    }}
                    style={{
                        fontSize:22,
                        color :'white', border:'3px solid #2e58ec', backgroundColor:'#2e58ec',
                        borderRadius:25, display:'flex',flexDirection:'row',
                        alignItems:'center', justifyContent:'center', height:50, marginTop:20, width:'80%'
                    }}>
                    <span>로그인</span>
                </div>
                <div
                    className={'_click'}
                    onClick={()=> document.location.href='http://pf.kakao.com/_fVHrG/chat'}
                    style={{
                        fontSize:22,
                        color :'#2e58ec', border:'3px solid #2e58ec', backgroundColor:'white',
                        borderRadius:25, display:'flex',flexDirection:'row',
                        alignItems:'center', justifyContent:'center', height:50, marginTop:20, width:'80%'
                    }}>
                    <span>비밀번호 초기화</span>
                </div>
                <div
                    className={'_click'}
                    onClick={()=>{
                        navigator('../join')
                    }}
                    style={{
                        fontSize:22,
                        color :'black', border:'3px solid black', backgroundColor:'#f7e111',
                        borderRadius:25, display:'flex',flexDirection:'row',
                        alignItems:'center', justifyContent:'center', height:50, marginTop:20, width:'80%'
                    }}>
                    <span>회원가입</span>
                </div>
            </div>

            {/*<Notice/>*/}
            <div style={{height:height*0.2}}></div>
        </div>
    )
}