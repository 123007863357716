import ACTION_TYPES from 'actions/actionsTypes';

const initUserState = {
    dictionary: {
        deliveryList : [],
    },
};

export function dictReducer(state=initUserState, action) {

    var { dictionary } = state;

    switch(action.type) {

        case ACTION_TYPES.SET_DICT_DELIVERY:
            return {
                dictionary: {
                    ...dictionary
                    , deliveryList : [...action['delivery_list']]
                }
            };


        default:
            return state;
    }
};
