import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import Header from "../header";
import Footer from "../footer";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "actions/userActions";
import AlertWindow from "../../lib/alertWindow";

export default function Common () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const [alertOpen, setAlertOpen]=useState(false);

    const userInfo = useSelector((state) => state.userReducer.userInfo)
    const location = useLocation();

    useEffect(()=>{
        console.log(location)
        // navigator('/service')
    },[location])
    useEffect(()=>{
        console.log(userInfo)
        if(!userInfo){
            dispatch(logoutUser());
            navigator('/')
        }
    },[userInfo])

    return (
        <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center'}}>
            {
                ['/service','/contact', '/account', '/common/lawList'].indexOf(location['pathname']) > -1 ?
                    <div style={{
                        width:'100%',
                        // borderRadius:15,
                        // backgroundColor: '#292b2c', //29location.pathname == '/dashboard' ? '#292b2c' : 'white',
                        backgroundColor:'white',
                        //   backgroundColor:'#F2F5F8',
                        overflowY:'auto',
                        minHeight:height*0.7
                    }}>
                        <Outlet/>
                    </div>
                    :
                    <div style={{width:'100%'}}>
                        <Header />
                        <div style={{
                            width:'100%',
                            // borderRadius:15,
                            // backgroundColor: '#292b2c', //29location.pathname == '/dashboard' ? '#292b2c' : 'white',
                            backgroundColor:'white',
                            //   backgroundColor:'#F2F5F8',
                            overflowY:'auto',
                            minHeight:height*0.7
                        }}>
                            <Outlet/>
                        </div>
                    </div>
            }


            <div style={{width:'100%'}}>
                <Footer />
            </div>

            <AlertWindow alertOpen={alertOpen}/>

        </div>
    )
}