import {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Button, Modal, Table, Nav, Navbar, Container, Alert, FloatingLabel, Form, Spinner} from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "lib/useWindowDimensions";
import Login from "./login";
import { FaAngleLeft } from "react-icons/fa6";
import {useSelector} from "react-redux";
import {API_SERVER} from "../devConfig";
import {loginUser} from "../actions/userActions";
import AlertWindow from "../lib/alertWindow";

export default function Account () {
    const {width, height} =useWindowDimensions();
    const navigator = useNavigate();
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const [modalApprove, setModalApprove] = useState(false);
    const [joinInfo, setJoinInfo] = useState({
        pwd : '',
        pwd_chk : '',
    })
    return (
        <div>
            <div style={{
                justifyContent:'center', alignItems:'center', flexWrap:'wrap',
                width:'100%',  display:'flex', flexDirection:'row',fontWeight:'bold', whiteSpace:'pre-wrap',
            }}>
                <div style={{
                    fontSize:20,
                    width:'100%', height:60, display:'flex', flexDirection:'row',
                    justifyContent:'space-between', alignItems:'center'
                }}>
                    <div
                        className={'_click'}
                        onClick={()=>{
                            navigator('/')
                        }}
                        style={{width:80,
                            textAlign:'left',
                            paddingLeft:15,
                        }}>
                        <FaAngleLeft/>
                    </div>
                    <span>내 계정정보</span>
                    <div style={{width:80}}/>
                </div>

                <div style={{
                    paddingBottom:50,
                    width:'85%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                    {
                        [
                            {key : 'is_complete', show_key : '인증여부'},
                            {key : 'phone', show_key : '휴대폰번호'},
                            {key : 'name', show_key : '이름'},
                            {key : 'company_num', show_key : '사업자등록번호'},
                            {key : 'pwd', show_key : '새 비밀번호'},
                            {key : 'pwd_chk', show_key : '새 비밀번호 재입력'},
                        ].map((sec, i)=>{
                            if(sec['key'].includes('pwd')){
                                return(
                                        <div style={{
                                            fontSize:20,
                                            marginTop:20,
                                            width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center',
                                            borderBottom:'1px solid lightgray'
                                        }}>
                                            <div style={{width:'100%'}}>
                                                <span>{sec['show_key']}</span>
                                                <div style={{borderBottom:'1px solid lightgray'}}>
                                                    <Form.Control
                                                        className={'_under'}
                                                        style={{width:'100%'}}
                                                        type={'password'}

                                                        onChange={(e)=>{
                                                            var value = e.target.value;
                                                            // value = value.replace(/[^0-9]/g, '')
                                                            var _joinInfo = joinInfo;
                                                            _joinInfo[sec['key']] = value;
                                                            setJoinInfo({..._joinInfo})

                                                        }}
                                                        // disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }else{
                                return(
                                    <div style={{
                                        fontSize:20,
                                        marginTop:20,
                                        width:'100%', display:'flex', flexDirection:'row',
                                        justifyContent:'space-between', alignItems:'center',
                                        borderBottom:'1px solid lightgray',
                                    }}>
                                        <div>
                                            <span>{sec['show_key']}</span>
                                            <div style={{marginTop:5}}>
                                            <span>{
                                                sec['key'] == 'is_complete'
                                                    ?(userInfo[sec['key']] == 'Y' ? '인증회원' : '미인증 회원')
                                                    :userInfo[sec['key']]
                                            }</span>
                                            </div>
                                        </div>

                                        {
                                            i == 0 &&
                                            <div
                                                className={'_click'}
                                                onClick={()=>{
                                                    setModalApprove(true)
                                                }}
                                                style={{
                                                    fontSize:18,
                                                    paddingLeft:20, paddingRight:20,
                                                    color :'white', border:'3px solid #2e58ec', backgroundColor:'#2e58ec',
                                                    borderRadius:25, display:'flex',flexDirection:'row',
                                                    alignItems:'center', justifyContent:'center', height:40
                                                }}>
                                                <span>인증방법 안내 ></span>
                                            </div>
                                        }
                                    </div>
                                )
                            }

                        })
                    }

                    <div
                        className={'_click'}
                        onClick={()=>{

                            var _joinInfo = {...joinInfo};
                            var _userInfo = {...userInfo};




                            if(_joinInfo['pwd'] == _joinInfo['pwd_chk']){

                                if(_joinInfo['pwd'].trim() == ''){
                                    window.alert('비밀번호를 확인해주세요');
                                    return;
                                }
                                _userInfo['pwd'] = _joinInfo['pwd'];

                                fetch(API_SERVER + '/matching/pwdChange' ,{
                                    method: 'POST',
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        userInfo:_userInfo
                                    })
                                })
                                    .then(res => res.json())
                                    .then(json => {
                                        console.log(json)
                                        if(json['suc']){
                                            window.alert('비밀번호가 변경되었습니다');
                                            navigator('/')

                                            console.log('pwdChg')
                                        }else{
                                            window.alert('비밀번호 변경에 실패하였습니다')
                                        }

                                    });
                            }else{
                                window.alert('비밀번호가 일치하지 않습니다')
                            }
                            console.log(_userInfo);
                            // console.log(_joinInfo);
                        }}
                        style={{
                            fontSize:22,
                            color :'white', border:'3px solid #2e58ec', backgroundColor:'#2e58ec',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:50, marginTop:20, width:'100%'
                        }}>
                        <span>비밀번호 변경하기</span>
                    </div>
                    <div
                        className={'_click'}
                        onClick={()=> document.location.href='http://pf.kakao.com/_fVHrG/chat'}

                        style={{
                            fontSize:22,
                            color :'#2e58ec', border:'3px solid #2e58ec', backgroundColor:'white',
                            borderRadius:25, display:'flex',flexDirection:'row',
                            alignItems:'center', justifyContent:'center', height:50, marginTop:20, width:'100%'
                        }}>
                        <span>정보변경 문의 (카카오톡 연결)</span>
                    </div>

                </div>
            </div>
            <AlertWindow alertApprove={modalApprove} setModalApprove={setModalApprove}/>




        </div>
    )
}